<template lang="pug">
    .nav-drawer
        template(v-if="$vuetify.breakpoint.mdAndUp")
            v-navigation-drawer(app permanent color="white")
                v-container
                    Logo
                    About
        template(v-else)
            header
                Logo
                About
</template>

<script>
const Logo = () => import("@/components/Logo.vue");
const About = () => import("@/components/About.vue");
export default {
  name: "NavDrawer",
  components: { Logo, About }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

::v-deep .v-navigation-drawer__border {
  display: none;
}
</style>
